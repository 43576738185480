import { EMPTY_CUSTOMER, EMPTY_VEHICLE, EMPTY_VEHICLE_SPECIFIC_FIELDS } from '@stimcar/libs-base';
import { EMPTY_CUSTOMER_FORM, EMPTY_FORM_WITH_VALIDATION_STATE } from '@stimcar/libs-uitoolkit';
import type {
  DeleteVehicleDialogState,
  SelectExistingCustomerDialogState,
  VehicleDialogState,
  VehicleForm,
  VehiclesState,
  WorkSheetDialogState,
} from './typings/store.js';

export const EMPTY_SELECT_EXISTING_CUSTOMER_DIALOG_SATE: SelectExistingCustomerDialogState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  active: false,
  customers: [],
  formData: {
    customerId: '',
    warnings: {},
  },
};

const EMPTY_VEHICLE_FORM: VehicleForm = {
  ...EMPTY_CUSTOMER_FORM,
  ...EMPTY_VEHICLE_SPECIFIC_FIELDS,
  mileage: '',
  dateOfRegistration: Date.now().toString(),
  warnings: {},
};

export const EMPTY_VEHICLE_DIALOG_STATE: VehicleDialogState = {
  active: false,
  mode: 'create',
  formSubmitted: false,
  formWarning: undefined,
  initialVehicle: undefined,
  initialCustomer: undefined,
  formSubmitClickedOnce: false,
  formData: EMPTY_VEHICLE_FORM,
  selectExistingCustomerDialogState: EMPTY_SELECT_EXISTING_CUSTOMER_DIALOG_SATE,
};

export const EMPTY_DELETE_VEHICLE_DIALOG_STATE: DeleteVehicleDialogState = {
  active: false,
  vehicleId: '',
};

export const EMPTY_WORKSHEET_DIALOG_STATE: WorkSheetDialogState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  active: false,
  formData: {
    files: [],
    siteId: '',
    warnings: {},
    workSheet: '',
  },
  vehicle: { ...EMPTY_VEHICLE, estimateRequests: [], customer: EMPTY_CUSTOMER },
};

export const EMPTY_VEHICLES_STATE: VehiclesState = {
  sites: [],
  vehicles: [],
  isLoading: true,
  searchFilter: '',
  vehicleDialog: EMPTY_VEHICLE_DIALOG_STATE,
  workSheetDialog: EMPTY_WORKSHEET_DIALOG_STATE,
  deleteVehicleDialog: EMPTY_DELETE_VEHICLE_DIALOG_STATE,
};
