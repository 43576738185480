import type {
  Customer,
  EntityServerActionList,
  RepositoryEntity,
  ServerAction,
  SpecificFields,
  Vehicle,
} from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { MLMStoreDef, StoreState } from '../store/typings/store.js';

type ActionWithEntityId<E extends RepositoryEntity> = ServerAction<E> & {
  readonly entityId: string;
};

export function updateCustomersStateFromSSE(
  { getState, actionDispatch }: ActionContext<MLMStoreDef, StoreState>,
  customersActions: readonly EntityServerActionList<Customer>[]
): void {
  const customersDispatch = actionDispatch.scopeProperty('customers').scopeProperty('customers');

  const { createsAndUpdatesPayload, deletedEntitiesIds } =
    computeChangesFromActions(customersActions);
  customersDispatch.applyPayload(createsAndUpdatesPayload);

  const { customers } = getState().customers;
  customersDispatch.setValue(customers.filter(({ id }) => !deletedEntitiesIds.includes(id)));
}

export function updateVehiclesStateFromSSE(
  { getState, actionDispatch }: ActionContext<MLMStoreDef, StoreState>,
  vehiclesActions: readonly EntityServerActionList<Vehicle>[]
): void {
  const vehiclesDispatch = actionDispatch.scopeProperty('vehicles').scopeProperty('vehicles');

  const { createsAndUpdatesPayload, deletedEntitiesIds } =
    computeChangesFromActions(vehiclesActions);
  vehiclesDispatch.applyPayload(createsAndUpdatesPayload);

  const { vehicles } = getState().vehicles;
  vehiclesDispatch.setValue(vehicles.filter(({ id }) => !deletedEntitiesIds.includes(id)));
}

type ComputeChangesFromActionsReturnType<E extends RepositoryEntity> = {
  readonly createsAndUpdatesPayload: (
    | { id: string }
    | ({ id: string } & SpecificFields<E>)
    | ({ id: string } & DeepPartial<SpecificFields<E>>)
  )[];
  readonly deletedEntitiesIds: readonly string[];
};

export function computeChangesFromActions<E extends RepositoryEntity>(
  actions: readonly EntityServerActionList<E>[]
): ComputeChangesFromActionsReturnType<E> {
  const actionsWithEntityIds: readonly ActionWithEntityId<E>[] = actions
    .map(({ entityId, actions }) => actions.map((action) => ({ ...action, entityId })))
    .flat();

  const createActions = actionsWithEntityIds.filter(({ type }) => type === 'create');
  const updateActions = actionsWithEntityIds.filter(({ type }) => type === 'update');
  const createsAndUpdatesPayload = [...createActions, ...updateActions].map(
    ({ entityId, payload }) => ({
      id: entityId,
      ...payload,
    })
  );

  const deletedEntitiesIds = actionsWithEntityIds
    .filter(({ type }) => type === 'archive' || type === 'close')
    .map(({ entityId }) => entityId);

  return { createsAndUpdatesPayload, deletedEntitiesIds };
}
