import React from 'react';
import ReactDOM from 'react-dom/client';
import type { Customer, EntityServerActionList, Vehicle } from '@stimcar/libs-base';
import type { ActionDispatch, AnyStoreDef, GetState } from '@stimcar/libs-uikernel';
import { HttpErrorCodes } from '@stimcar/libs-base';
import { getHttpStatusCode, Logger } from '@stimcar/libs-kernel';
import {
  BrowserKeyValueStorageImpl,
  createDispatchableStore,
  getInitialTheme,
  getWindowState,
  installStateConsoleHook,
} from '@stimcar/libs-uikernel';
import { BrowserEventSourceFactory, BrowserFormDataFactory } from '@stimcar/libs-uitoolkit';
import { MlmSSEMessages } from '@stimcar/mlm-libs-common';
import type { MLMStoreDef } from './store/typings/store.js';
import { MlmHTTPClientImpl } from './app/http/MlmHttpClientImpl.js';
import { MlmApp } from './app/MlmApp.js';
import {
  updateCustomersStateFromSSE,
  updateVehiclesStateFromSSE,
} from './sse/updateStateFromSSE.js';
import { EMPTY_STORE_STATE } from './store/store.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export type DispatchErrorHandler<SD extends AnyStoreDef> = (
  dispatch: ActionDispatch<SD, SD['globalState']>,
  getState: GetState<SD['globalState']>,
  error: Error
) => Promise<void> | void;

// Create app store
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [globalDispatch, $gs, appStoreContextSetter, appStoreChangeListenerRegisterer] =
  createDispatchableStore<MLMStoreDef>(
    { ...EMPTY_STORE_STATE, window: getWindowState(), bulmaTheme: getInitialTheme() },
    (dispatch, _getState, error) => {
      if (getHttpStatusCode(error) === HttpErrorCodes.UNAUTHORIZED) {
        // Just wait for keycloak to display the sign in form
      } else {
        log.error('Unexpected error', error);
        dispatch.setProperty('error', 'unexpectedError');
      }
    }
  );

// Register state console hook
installStateConsoleHook(appStoreChangeListenerRegisterer);

const httpClient = new MlmHTTPClientImpl(
  fetch.bind(window),
  BrowserFormDataFactory,
  BrowserEventSourceFactory
);

// Initialize context
appStoreContextSetter({
  httpClient,
  keyValueStorage: new BrowserKeyValueStorageImpl(),
});

httpClient.registerServerMessageListener(
  MlmSSEMessages.MLM_ENTITY_MESSAGES('customer'),
  async (customersActions: readonly EntityServerActionList<Customer>[]) =>
    globalDispatch.exec(updateCustomersStateFromSSE, customersActions)
);
httpClient.registerServerMessageListener(
  MlmSSEMessages.MLM_ENTITY_MESSAGES('vehicle'),
  async (vehiclesActions: readonly EntityServerActionList<Vehicle>[]) =>
    globalDispatch.exec(updateVehiclesStateFromSSE, vehiclesActions)
);
httpClient.startSSE();

const root: HTMLElement | null = document.getElementById('root');
if (root === null) {
  // This is not supposed to happen, but if it ever happens, we open a native popup to warn the
  // user (otherwise a simple log in the console may not be enough to understand what happens)
  // eslint-disable-next-line no-alert
  window.alert('Unexpected error: DOM root not found');
} else {
  const reactRoot = ReactDOM.createRoot(root);
  reactRoot.render(<MlmApp $gs={$gs} />);
}
