import type { KnownKeysOf, RepositoryEntities } from '@stimcar/libs-base';
import { WAN_API_ROUTE_PREFIX } from '@stimcar/libs-kernel';
import type { InterventionStatus, InterventionStatusLabel } from './typings.js';

export const MlmSSEMessages = {
  SSE: `${WAN_API_ROUTE_PREFIX}/sse`,
  MLM_ENTITY_MESSAGES: (entityName: KnownKeysOf<RepositoryEntities>): string =>
    `${WAN_API_ROUTE_PREFIX}/${entityName}/messages`,
};

export const MlmAuthenticationRoutes = {
  LOGIN: `${WAN_API_ROUTE_PREFIX}/auth/login`,
  LOGIN_CALLBACK: `${WAN_API_ROUTE_PREFIX}/auth/callback`,
  REFRESH_TOKEN: `${WAN_API_ROUTE_PREFIX}/auth/token/refresh`,
  LOGOUT: `${WAN_API_ROUTE_PREFIX}/auth/logout`,
};

export const MlmBackendRoutes = {
  GET_CUSTOMERS: `${WAN_API_ROUTE_PREFIX}/customers/list`,
  CREATE_CUSTOMER: `${WAN_API_ROUTE_PREFIX}/customers/create`,
  UPDATE_CUSTOMER: (customerId: string) => `${WAN_API_ROUTE_PREFIX}/customers/${customerId}/update`,
  DELETE_CUSTOMER: (customerId: string) => `${WAN_API_ROUTE_PREFIX}/customers/${customerId}/delete`,
  GET_VEHICLES: `${WAN_API_ROUTE_PREFIX}/vehicles/list`,
  GET_VEHICLES_INTERVENTIONS: `${WAN_API_ROUTE_PREFIX}/vehicles/interventions/list`,
  CREATE_VEHICLE: `${WAN_API_ROUTE_PREFIX}/vehicles/create`,
  UPDATE_VEHICLE: (vehicleId: string) => `${WAN_API_ROUTE_PREFIX}/vehicles/${vehicleId}/update`,
  DELETE_VEHICLE: (vehicleId: string) => `${WAN_API_ROUTE_PREFIX}/vehicles/${vehicleId}/delete`,
  GET_SITES: `${WAN_API_ROUTE_PREFIX}/sites/list`,
  CREATE_KANBAN_FROM_WORKSHEET: `${WAN_API_ROUTE_PREFIX}/vehicles/createKanbanFromWorksheet`,
};

export const WORKSHEET_FILENAME = 'workSheet.md';

export const INTERVENTION_STATUSES: Record<InterventionStatusLabel, InterventionStatus> = {
  WAITING_FOR_HANDLING: {
    order: 0,
    color: 'is-primary is-light',
    label: 'WAITING_FOR_HANDLING',
  },
  EXPERTISE: {
    order: 1,
    color: 'is-primary is-light',
    label: 'EXPERTISE',
  },
  SPARE_PART_REFERENCE: {
    order: 2,
    color: 'is-primary is-light',
    label: 'SPARE_PART_REFERENCE',
  },
  WAITING_FOR_VALIDATION: {
    order: 3,
    color: 'is-warning',
    label: 'WAITING_FOR_VALIDATION',
  },
  WAITING_FOR_SPARE_PARTS: {
    order: 4,
    color: 'is-primary',
    label: 'WAITING_FOR_SPARE_PARTS',
  },
  ONLINE_SELL: {
    order: 5,
    color: 'is-primary',
    label: 'ONLINE_SELL',
  },
  WORK_IN_PROGRESS: {
    order: 6,
    color: 'is-primary',
    label: 'WORK_IN_PROGRESS',
  },
  TECHNICAL_CONTROL: {
    order: 7,
    color: 'is-primary',
    label: 'TECHNICAL_CONTROL',
  },
  DELIVERY: {
    order: 8,
    color: 'is-warning is-light',
    label: 'DELIVERY',
  },
  ADMINISTRATION: {
    order: 9,
    color: 'is-success is-light',
    label: 'ADMINISTRATION',
  },
  FINISHED: {
    order: 10,
    color: 'is-success',
    label: 'FINISHED',
  },
};
