import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Civility } from '@stimcar/libs-base';
import type {
  ActionContext,
  AnyStoreDef,
  StoreStateSelector,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import { CIVILITIES } from '@stimcar/libs-base';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import type { HorizontalFormFieldProps } from '../../bulma/form/FormField.js';
import type { FormFieldEntry } from '../../bulma/form/typings/FormFieldEntry.js';
import type {
  CheckFormFieldContentActions,
  FormWithValidationState,
} from '../../hooks/useFormWithValidation.js';
import { BinaryChoiceAddOnButtonsFormField } from '../../bulma/form/BinaryChoiceAddOnButtonsFormField.js';
import { CheckboxFormField } from '../../bulma/form/CheckboxFormField.js';
import { InputFormField } from '../../bulma/form/InputFormField.js';
import { SelectFormField } from '../../bulma/form/SelectFormField.js';
import { TextAreaFormField } from '../../bulma/form/TextAreaFormField.js';
import type { CustomerFormData } from './typings/store.js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkEditCustomerFormFieldContentActions: CheckFormFieldContentActions<
  AnyStoreDef,
  FormWithValidationState<CustomerFormData>
> = {
  // eslint-disable-next-line @typescript-eslint/require-await
  invoiceId: async ({ value, t }): Promise<string | undefined> => {
    if (Number.isNaN(parseFloat(value))) {
      return t('custom:editCustomerForm.warnings.invoiceIdMustBeANumber');
    }
    return undefined;
  },
};

const CONTRACT_MANDATORY_FIELDS: readonly (keyof CustomerFormData)[] = [
  'contractCode',
  'shortName',
];

const COMMON_MANDATORY_FIELDS: readonly (keyof CustomerFormData)[] = [
  'type',
  'civility',
  'street',
  'zipCode',
  'city',
  'invoiceId',
];

const INDIVIDUAL_MANDATORY_FIELDS: readonly (keyof CustomerFormData)[] = [
  ...COMMON_MANDATORY_FIELDS,
  'lastName',
  'firstName',
];

const PROFESSIONAL_MANDATORY_FIELDS: readonly (keyof CustomerFormData)[] = [
  ...COMMON_MANDATORY_FIELDS,
  'company',
];

export const useEditCustomerFormMandatoryFields = <SD extends AnyStoreDef>(
  $formData: StoreStateSelector<SD, CustomerFormData>,
  hideContractIdField?: boolean,
  additionalRequiredFields: readonly (keyof CustomerFormData)[] = []
): readonly (keyof CustomerFormData)[] => {
  const type = useGetState($formData.$type);
  return useMemo(
    (): readonly (keyof CustomerFormData)[] => [
      ...(type === 'individual' ? INDIVIDUAL_MANDATORY_FIELDS : PROFESSIONAL_MANDATORY_FIELDS),
      ...(hideContractIdField ? [] : CONTRACT_MANDATORY_FIELDS),
      ...additionalRequiredFields,
    ],
    [type, hideContractIdField, additionalRequiredFields]
  );
};

function CustomerIdentityForm<SD extends AnyStoreDef>({
  $formData,
  disabled,
  horizontal,
}: BaseEditCustomerFormProps<SD>): JSX.Element {
  const [t] = useTranslation('custom');
  const type = useGetState($formData.$type);
  const isIndividual = type === 'individual';
  const civility = useGetState($formData.$civility);
  const isCompanyCivility = civility === 'company';

  const civilityEntries: readonly FormFieldEntry<Civility>[] = useMemo(
    () =>
      (isIndividual ? CIVILITIES.filter((c) => c !== 'company') : CIVILITIES).map(
        (c): FormFieldEntry<Civility> => ({
          id: c,
          label: t(`common:civilities.${c}`),
        })
      ),
    [isIndividual, t]
  );

  const professionalEntry = useMemo(() => {
    return {
      id: 'professional',
      selectedClass: 'is-primary',
      iconId: 'building',
      label: t('editCustomerForm.types.professional'),
    };
  }, [t]);

  const individualEntry = useMemo(() => {
    return {
      id: 'individual',
      selectedClass: 'is-primary',
      iconId: 'male',
      label: t('editCustomerForm.types.individual'),
    };
  }, [t]);

  const onTypeChangeTrigger = useActionCallback(
    ({
      actionDispatch,
      getState,
    }: ActionContext<SD, WithFormValidationWarnings<CustomerFormData>>) => {
      const { type, civility } = getState();
      // Individual case
      if (type === 'individual') {
        actionDispatch.setProperty('company', '');
        if (civility === 'company') {
          actionDispatch.setProperty('civility', 'notset');
        }
      }
    },
    [],
    $formData
  );

  const onCivilityChangeTrigger = useActionCallback(
    ({
      actionDispatch,
      getState,
    }: ActionContext<SD, WithFormValidationWarnings<CustomerFormData>>) => {
      const { civility } = getState();
      // Individual case
      if (civility === 'company') {
        actionDispatch.setProperty('firstName', '');
      }
    },
    [],
    $formData
  );

  const $civilityWithChangeTrigger = useSelectorWithChangeTrigger(
    $formData.$civility,
    onCivilityChangeTrigger
  );

  return (
    <>
      <BinaryChoiceAddOnButtonsFormField
        label={t('editCustomerForm.type')}
        leftButton={professionalEntry}
        rightButton={individualEntry}
        $={useSelectorWithChangeTrigger($formData.$type, onTypeChangeTrigger)}
        horizontal={horizontal}
        disabled={disabled}
      />
      <InputFormField
        label={t('editCustomerForm.company')}
        $={$formData.$company}
        horizontal={horizontal}
        readOnly={isIndividual || disabled}
        disabled={isIndividual || disabled}
      />
      <SelectFormField
        label={t('editCustomerForm.civility')}
        entries={civilityEntries}
        $={$civilityWithChangeTrigger}
        horizontal={horizontal}
        disabled={disabled}
      />
      <InputFormField
        label={t('editCustomerForm.lastName')}
        $={$formData.$lastName}
        horizontal={horizontal}
        disabled={disabled}
      />
      <InputFormField
        label={t('editCustomerForm.firstName')}
        $={$formData.$firstName}
        horizontal={horizontal}
        readOnly={isCompanyCivility || disabled}
        disabled={isCompanyCivility || disabled}
      />
    </>
  );
}

interface CustomerPhoneMailInvoiceForm<SD extends AnyStoreDef>
  extends BaseEditCustomerFormProps<SD>,
    Pick<EditCustomerFormProps<SD>, 'hideInvoiceIdField' | 'hideVATField'> {}

function CustomerPhoneMailInvoiceForm<SD extends AnyStoreDef>({
  disabled,
  $formData,
  horizontal,
  hideVATField = false,
  hideInvoiceIdField = false,
}: CustomerPhoneMailInvoiceForm<SD>): JSX.Element {
  const [t] = useTranslation('custom');

  return (
    <>
      <InputFormField
        label={t('editCustomerForm.email')}
        $={$formData.$email}
        horizontal={horizontal}
        disabled={disabled}
      />
      <InputFormField
        label={t('editCustomerForm.phone')}
        $={$formData.$phone}
        horizontal={horizontal}
        disabled={disabled}
      />
      {!hideInvoiceIdField && (
        <InputFormField
          label={t('editCustomerForm.invoiceId')}
          $={$formData.$invoiceId}
          horizontal={horizontal}
          disabled={disabled}
        />
      )}
      {!hideVATField && (
        <CheckboxFormField
          label={t('editCustomerForm.ignoreVAT')}
          $={$formData.$ignoreVAT}
          horizontal={horizontal}
          disabled={disabled}
        />
      )}
    </>
  );
}

function CustomerAddressForm<SD extends AnyStoreDef>({
  $formData,
  disabled,
  horizontal,
}: BaseEditCustomerFormProps<SD>): JSX.Element {
  const [t] = useTranslation('custom');

  return (
    <>
      <TextAreaFormField
        label={t('editCustomerForm.street')}
        $={$formData.$street}
        horizontal={horizontal}
        disabled={disabled}
      />
      <InputFormField
        label={t('editCustomerForm.zipCode')}
        $={$formData.$zipCode}
        horizontal={horizontal}
        disabled={disabled}
      />
      <InputFormField
        label={t('editCustomerForm.city')}
        $={$formData.$city}
        horizontal={horizontal}
        disabled={disabled}
      />
    </>
  );
}

interface BaseEditCustomerFormProps<SD extends AnyStoreDef> {
  readonly $formData: StoreStateSelector<SD, WithFormValidationWarnings<CustomerFormData>>;
  readonly disabled?: boolean;
  readonly horizontal?: boolean | HorizontalFormFieldProps;
}
export interface EditCustomerFormProps<SD extends AnyStoreDef>
  extends BaseEditCustomerFormProps<SD> {
  readonly orientation?: 'raw vertical' | 'horizontal columns';
  readonly hideVATField?: boolean;
  readonly hideInvoiceIdField?: boolean;
  readonly hideContractIdField?: boolean;
  readonly contractCodes?: readonly string[];
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  bodyFlexGrow: 3,
  labelFlexGrow: 1,
};

export function EditCustomerForm<SD extends AnyStoreDef>({
  $formData,
  disabled = false,
  contractCodes = [],
  hideVATField = false,
  hideInvoiceIdField = false,
  hideContractIdField = false,
  orientation = 'raw vertical',
}: EditCustomerFormProps<SD>): JSX.Element {
  const [t] = useTranslation('custom');
  const contractCodesWithEmptyValue = useMemo(
    (): readonly string[] => (contractCodes.includes('') ? contractCodes : ['', ...contractCodes]),
    [contractCodes]
  );

  return (
    <>
      {orientation === 'raw vertical' && (
        <>
          {!hideContractIdField && (
            <>
              <SelectFormField
                label={t('editCustomerForm.contractCode')}
                entries={contractCodesWithEmptyValue}
                $={$formData.$contractCode}
                horizontal={HORIZONTAL_PROPS}
                disabled={disabled}
              />
              <InputFormField
                label={t('editCustomerForm.shortName')}
                $={$formData.$shortName}
                horizontal={HORIZONTAL_PROPS}
                readOnly={disabled}
                disabled={disabled}
              />
            </>
          )}
          <CustomerIdentityForm
            $formData={$formData}
            disabled={disabled}
            horizontal={HORIZONTAL_PROPS}
          />
          <CustomerAddressForm
            $formData={$formData}
            disabled={disabled}
            horizontal={HORIZONTAL_PROPS}
          />
          <CustomerPhoneMailInvoiceForm
            disabled={disabled}
            $formData={$formData}
            hideVATField={hideVATField}
            horizontal={HORIZONTAL_PROPS}
            hideInvoiceIdField={hideInvoiceIdField}
          />
        </>
      )}
      {orientation === 'horizontal columns' && (
        <div className="columns">
          <div className="column">
            <CustomerIdentityForm $formData={$formData} disabled={disabled} />
          </div>
          <div className="column">
            <CustomerAddressForm $formData={$formData} disabled={disabled} />
          </div>
          <div className="column">
            <CustomerPhoneMailInvoiceForm
              disabled={disabled}
              $formData={$formData}
              hideVATField={hideVATField}
              hideInvoiceIdField={hideInvoiceIdField}
            />
          </div>
        </div>
      )}
    </>
  );
}
