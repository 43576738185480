import type { JSX } from 'react';
import React, { useEffect } from 'react';
import type { KeyValueStorage } from '@stimcar/libs-kernel';
import type {
  ActionContext,
  GlobalStoreStateSelector,
  StoreDef,
} from '../../zustand/typings/zustand-dispatch.js';
import { useActionCallback, useGetState } from '../../zustand/zustand-hooks.js';

const SELECTED_THEME_PROPERTY_KEY = 'theme';

export type LightDarkTheme = 'light' | 'dark';

export type LightDarkThemeSupportStateContainer = {
  readonly bulmaTheme: LightDarkTheme;
};

export interface LightDarkThemeSupportActionContext {
  readonly keyValueStorage: KeyValueStorage;
}

export type LightDarkThemeSupportStoreDef = StoreDef<
  LightDarkThemeSupportStateContainer,
  LightDarkThemeSupportActionContext
>;

export function getInitialTheme(): LightDarkTheme {
  const currentTheme = localStorage.getItem(SELECTED_THEME_PROPERTY_KEY);
  if (currentTheme !== null) {
    return currentTheme as LightDarkTheme;
  }
  const isDarkThemePreferred =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  return isDarkThemePreferred ? 'dark' : 'light';
}

function toggleThemeAction<SD extends LightDarkThemeSupportStoreDef>({
  actionDispatch,
  keyValueStorage,
  getState,
}: ActionContext<SD, LightDarkTheme>) {
  const newTheme: LightDarkTheme = getState() === 'dark' ? 'light' : 'dark';
  actionDispatch.setValue(newTheme);
  keyValueStorage.setItem(SELECTED_THEME_PROPERTY_KEY, newTheme);
}

interface Props<SD extends LightDarkThemeSupportStoreDef> {
  readonly $gs: GlobalStoreStateSelector<SD>;
}

export function LightDarkThemeSelector<SD extends LightDarkThemeSupportStoreDef>({
  $gs,
}: Props<SD>): JSX.Element {
  const theme = useGetState($gs.$bulmaTheme);

  useEffect(() => {
    document.documentElement.className = `theme-${theme}`;
  }, [theme]);

  const toggleThemeActionCallback = useActionCallback(toggleThemeAction, [], $gs.$bulmaTheme);

  return (
    <button type="button" aria-label="theme" onClick={toggleThemeActionCallback}>
      <span className="icon">
        <i
          className={`fa-solid fa-${theme === 'dark' ? 'moon' : 'sun'} ${theme === 'light' ? 'has-text-warning' /* Yellow sun */ : 'has-text-white'}`}
        />
      </span>
    </button>
  );
}
