import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { EMPTY_EDIT_CUSTOMER_DIALOG_STATE } from '@stimcar/libs-uitoolkit';
import type { MLMStoreDef } from '../../store/typings/store.js';
import { EMPTY_DELETE_CUSTOMER_DIALOG_STATE } from '../pages/customers/store/store.js';
import {
  EMPTY_DELETE_VEHICLE_DIALOG_STATE,
  EMPTY_VEHICLE_DIALOG_STATE,
} from '../pages/vehicles/store/store.js';

export function useOnNavigation($gs: GlobalStoreStateSelector<MLMStoreDef>): void {
  const location = useLocation();

  const resetErrorStateOnNavigationAsyncEffect = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setValue(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
    $gs.$error
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    resetErrorStateOnNavigationAsyncEffect();
  }, [resetErrorStateOnNavigationAsyncEffect]);

  const closeModalsOnNavigationAsyncEffect = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.applyPayload({
        customers: {
          customerDialog: EMPTY_EDIT_CUSTOMER_DIALOG_STATE,
          deleteCustomerDialog: EMPTY_DELETE_CUSTOMER_DIALOG_STATE,
        },
        vehicles: {
          vehicleDialog: EMPTY_VEHICLE_DIALOG_STATE,
          deleteVehicleDialog: EMPTY_DELETE_VEHICLE_DIALOG_STATE,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
    $gs
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    closeModalsOnNavigationAsyncEffect();
  }, [closeModalsOnNavigationAsyncEffect]);
}
