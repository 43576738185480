import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { MLM_FRONTEND_ROUTES } from '../constants.js';
import './NavBar.scss';

const NAVBAR_ITEM_WIDTH = 100 / Object.entries(MLM_FRONTEND_ROUTES).length;

export function NavBar(): JSX.Element {
  const [t] = useTranslation('mlm');

  return (
    <nav
      role="navigation"
      aria-label="main navigation"
      className="navbar mlm-navbar is-fixed-bottom is-flex is-justify-content-space-between is-align-items-center"
    >
      <div className="navbar-menu is-active p-0 is-justify-content-center">
        <div className="navbar-items-container is-flex">
          {Object.entries(MLM_FRONTEND_ROUTES).map(([key, { path, icon }]) => (
            <NavLink
              key={key}
              to={path}
              style={{ width: `${NAVBAR_ITEM_WIDTH}%` }}
              className={({ isActive }) =>
                `navbar-item${isActive ? ' is-active has-text-weight-bold' : ''} has-background-inherit is-tab`
              }
            >
              <div className="navbar-item-container has-text-centered">
                <span className="icon has-text-primary is-medium">
                  <i className={`fas fa-${icon} fa-lg`} />
                </span>
                <p>{t(`navBar.${key}`)}</p>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
}
