type MlmRoute = {
  readonly path: string;
  readonly icon: string;
};

export const MLM_FRONTEND_ROUTES: Record<string, MlmRoute> = {
  HOME: { path: '/', icon: 'house' },
  VEHICLES: { path: '/vehicles', icon: 'car' },
  CUSTOMERS: { path: '/customers', icon: 'user-group' },
  PROFILE: { path: '/profile', icon: 'user' },
};

export const MlmLocalStorageKeys = {
  LAST_SELECTED_SITE_ID_FOR_WORKSHEET_CREATION: `lastSelectedSiteIdForWorksheetCreation`,
};
