import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetState } from '@stimcar/libs-uikernel';
import type { MlmAppProps } from './MlmApp.js';

type Props = MlmAppProps;

export function UnexpectedError({ $gs }: Props): JSX.Element {
  const [t] = useTranslation('mlm');
  const error = useGetState($gs.$error);
  return (
    <section className="section">
      <h1 className="title">{t(`errors.${error}.title`)}</h1>
      <h2 className="subtitle">{t(`errors.${error}.subtitle`)}</h2>
    </section>
  );
}
