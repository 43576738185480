import { createBrowserHistory } from 'history';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { createDispatchableStore, installStateConsoleHook } from '@stimcar/libs-uikernel';
import { ErrorBoundary } from '../react/custom/misc/ErrorBoundary.js';
import type { BaseStoreDef, ProgressBarMonitor } from './state/typings/store.js';
import type { AppDecorator, AppEnvironment, BootMode, LaunchAppArgs } from './typings/app.js';
import { BaseStoreAppWrapper } from './internal/progress/BaseStoreAppWrapper.js';
import { WithProgressMonitorAppWrapper } from './internal/progress/WithProgressMonitorAppWrapper.js';
import { baseAppContainerErrorHandler } from './state/store.js';

export const RESET_DATA_FULL_PATH = `/reset`;
export const RESET_DATA_SOFT_FULL_PATH = `${RESET_DATA_FULL_PATH}/soft`;
export const RESET_DATA_HARD_FULL_PATH = `${RESET_DATA_FULL_PATH}/hard`;

// Decorator that does nothing (default decorator)
const NoopAppDecorator: AppDecorator = ({ children }) => <>{children}</>;

export function launchApp<SD extends BaseStoreDef, ENV extends AppEnvironment>({
  environment,
  initialStoreState,
  bindEnvironmentToStore,
  createStoreActionContext,
  onStoreReadyAction,
  appComponent,
  appDecoratorComponent: AppDecorator = NoopAppDecorator,
  t,
}: LaunchAppArgs<SD, ENV>): void {
  // Create history
  const history = createBrowserHistory({ window });

  // Create app store
  const [appStoreDispatch, $gs, appStoreContextSetter, appStoreChangeListenerRegisterer] =
    createDispatchableStore<SD>(initialStoreState, baseAppContainerErrorHandler);
  // Register state console hook
  installStateConsoleHook(appStoreChangeListenerRegisterer);

  // Create environment
  const bootOrReset = async (progressBarMonitor: ProgressBarMonitor, mode: BootMode) => {
    const { href } = document.location;
    let selectedBootMode: BootMode = mode;
    if (href.endsWith(RESET_DATA_FULL_PATH) || href.endsWith(RESET_DATA_SOFT_FULL_PATH)) {
      selectedBootMode = 'SoftReset';
    } else if (href.endsWith(RESET_DATA_HARD_FULL_PATH)) {
      selectedBootMode = 'HardReset';
    } else {
      selectedBootMode = mode;
    }
    // Perform an environment reset if needed (and if the environment
    // supports it)
    if (
      (selectedBootMode === 'HardReset' || selectedBootMode === 'SoftReset') &&
      environment.reset !== undefined
    ) {
      progressBarMonitor.setLabel(t('refitit:boot.clearData'));
      // Remove environment data (repository, local storage, ...)
      await environment.reset(selectedBootMode === 'HardReset');
    }
    // Reset global state
    await appStoreDispatch.setValue(initialStoreState);
    // Bind environment listeners with store dispatch
    if (bindEnvironmentToStore) {
      await bindEnvironmentToStore(environment, appStoreDispatch, progressBarMonitor, t);
    }
    // Initialize context
    const context = await createStoreActionContext(environment, progressBarMonitor, t);
    appStoreContextSetter(context);
    // If an state initialization action is provided, execute it
    if (onStoreReadyAction) {
      await appStoreDispatch.exec(onStoreReadyAction);
    }
  };

  const root: HTMLElement | null = document.getElementById('root');
  if (root === null) {
    // This is not supposed to happen, but if it ever happens,
    // we open a native popup to warn the user (otherwises
    // a simple log in the console may not be enough to
    // understand what happens)
    // eslint-disable-next-line no-alert
    window.alert('Unexpected error: DOM root not found');
  } else {
    const reactRoot = ReactDOM.createRoot(root);
    reactRoot.render(
      <ErrorBoundary>
        <Suspense fallback="<div>Loading...</div>">
          <AppDecorator>
            <WithProgressMonitorAppWrapper
              contextSetter={appStoreContextSetter}
              history={history}
              bootOrReset={bootOrReset}
              bootOrResetMaxProgress={100 /* consider the boot process max progress is 100 */}
            >
              <BaseStoreAppWrapper $gs={$gs} appComponent={appComponent} />
            </WithProgressMonitorAppWrapper>
          </AppDecorator>
        </Suspense>
      </ErrorBoundary>
    );
  }
}
