import { useEffect } from 'react';
import type { BulmaMode } from '../../react/bulma/typings/bulma.js';
import type { GlobalStoreStateSelector, StoreDef } from '../../zustand/typings/zustand-dispatch.js';
import { BULMA } from '../../react/bulma/constants.js';
import { useActionCallback } from '../../zustand/zustand-hooks.js';

export type WindowState = {
  readonly width: number;
  readonly height: number;
  readonly bulmaMode: BulmaMode;
};

export type WindowStateContainer = {
  readonly window: WindowState;
};

export type WindowStateStoreDef = StoreDef<WindowStateContainer, object>;

function getBulmaMode(width: number): BulmaMode {
  if (width < BULMA.TABLET_MODE_LOWER_WIDTH) {
    return 'Mobile';
  }
  if (width < BULMA.DESKTOP_MODE_LOWER_WIDTH) {
    return 'Tablet';
  }
  if (width < BULMA.WIDESCREEN_MODE_LOWER_WIDTH) {
    return 'Desktop';
  }
  if (width < BULMA.FULLHD_MODE_LOWER_WIDTH) {
    return 'Widescreen';
  }
  return 'FullHD';
}

export function getWindowState(): WindowState {
  const { innerHeight, innerWidth } = window;
  return { width: innerWidth, height: innerHeight, bulmaMode: getBulmaMode(innerWidth) };
}

export function useWindowSizeTracker<SD extends WindowStateStoreDef>(
  $gs: GlobalStoreStateSelector<SD>
) {
  const eventListener = useActionCallback(
    function windowResizeHandlerAction({ actionDispatch }) {
      // Execute the code within an action in order to generate one only state change
      // (or even none if both width & height are not modified)
      actionDispatch.setValue(getWindowState());
    },
    [],
    $gs.$window
  );

  useEffect(
    function registerWindowSizeTracker(): () => void {
      // Register a click listener
      // JS event loop handle listeners like asynchronous notifications. The signature of the callback is
      // () => void but it can handle async callbacks
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.addEventListener('resize', eventListener, false);
      return (): void => {
        // Unregister the click listener
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        window.removeEventListener('resize', eventListener, false);
      };
    },
    [eventListener]
  );
}
