export const isTruthy = <T extends object | number | string | boolean>(
  arg: null | undefined | T
): arg is T => arg !== undefined && arg !== null;

export const isTruthyAndNotEmpty = (arg: string | undefined | null): arg is string =>
  isTruthy(arg) && arg.trim() !== '';

/**
 * Return a function that compares a given objects to other ones.
 * @param value the value to compare to.
 */
export function equals<T>(value: T): (other: T) => boolean {
  return (other): boolean => value === other;
}

export function nonnull<T>(value: T | null | undefined, message?: string): T {
  if (value === undefined || value === null) {
    throw new Error(`Unexpected null or undefined value${message ? ` (${message})` : ''}`);
  }
  return value;
}

export function isInteger(value: string): boolean {
  // We have to use a regex instead of Number.parseInt because parseInt will do some magic to be able to convert the given string
  // into a number, blocking us to know if the given string is a number or not.
  // For instance Number.parseInt('1234p', 10) return the number 1234
  return /^-?\d+$/.test(value);
}
