import { EMPTY_EDIT_CUSTOMER_DIALOG_STATE } from '@stimcar/libs-uitoolkit';
import type { CustomersState, DeleteCustomerDialogState } from './typings/store.js';

export const EMPTY_DELETE_CUSTOMER_DIALOG_STATE: DeleteCustomerDialogState = {
  active: false,
  customerId: '',
};

export const EMPTY_CUSTOMERS_STATE: CustomersState = {
  customers: [],
  isLoading: true,
  searchFilter: '',
  customerDialog: EMPTY_EDIT_CUSTOMER_DIALOG_STATE,
  deleteCustomerDialog: EMPTY_DELETE_CUSTOMER_DIALOG_STATE,
};
