import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import type {
  Contract,
  ContractAttributeDesc,
  ContractMandatoryFields,
  KanbanContract,
  MandatoryFields,
  MemoDesc,
  SharedUIContract,
  UIContract,
} from '../../model/typings/contract.js';
import type { Customer } from '../../model/typings/customer.js';
import type { AttachmentFolder } from '../../model/typings/general.js';
import {
  ACCEPTANCE_ATTACHMENT_FOLDER,
  ESTIMATE_ATTACHMENT_FOLDER,
  EXPERTISE_ATTACHMENTS_FOLDER,
  INVOICE_ATTACHMENT_FOLDER,
  QUALITY_CONTROL_ATTACHMENT_FOLDER,
  REGISTRATION_DOCUMENTS_ATTACHMENTS_FOLDER,
  TC_ATTACHMENT_FOLDER,
  WORK_SHEET_ATTACHMENTS_FOLDER,
} from '../../model/globalConstants.js';

function findContractByCode<T extends Pick<UIContract, 'code'>>(
  contracts: readonly T[],
  contractCode: string | undefined
): T | undefined {
  return contracts.find((c) => c.code === contractCode);
}

function getMandatoryFieldsForContract(
  mandatoryFields: MandatoryFields | undefined
): readonly ContractMandatoryFields[] {
  const contractMandatoryFields: ContractMandatoryFields[] = [];
  if (mandatoryFields?.purchaseOrders) {
    contractMandatoryFields.push('purchaseOrders');
  }
  return contractMandatoryFields;
}

function getSharedUIContractFromContract(contract: Contract): SharedUIContract {
  return {
    code: contract.code,
    packageDealDatabase: contract.packageDealDatabase,
    documents: contract.configuration.documents.filter((d) => d.isShareable),
    attributeDescs: contract.configuration.attributeDescs,
    estimateMention: contract.configuration.estimateMention,
    memoDescs: contract.configuration.memoDescs,
  };
}

function getUIContractFromContract(contract: Contract): UIContract {
  const uiContract = getSharedUIContractFromContract(contract);
  return {
    ...uiContract,
    hourlyRate: contract.configuration.hourlyRate,
    sparePartManagementType: contract.configuration.sparePartManagementType,
    sparePartMarginPercentage: contract.configuration.sparePartMarginPercentage,
    pkgDealDescCodesForKanban: contract.configuration.pkgDealDescCodesForKanban,
    pkgDealDescCodesForPurchaseOrder: contract.configuration.pkgDealDescCodesForPurchaseOrder,
    documents: contract.configuration.documents,
    roundPriceTo: contract.configuration.roundPriceTo,
    expertHints: contract.configuration.expertHints,
    upstreamTC: contract.configuration.upstreamTC,
    mandatoryFields: contract.configuration.mandatoryFields,
    providerSpecificMargin: contract.configuration.providerSpecificMargin,
    standardPicturesConfigurationKey: contract.configuration.standardPicturesConfigurationKey,
    standardPicturesConfiguration: contract.configuration.standardPicturesConfiguration,
  };
}

function getKanbanContractFromUIContract(contract: UIContract): KanbanContract {
  const {
    code,
    hourlyRate,
    packageDealDatabase,
    providerSpecificMargin,
    sparePartManagementType,
    sparePartMarginPercentage,
    roundPriceTo,
    mandatoryFields,
    pkgDealDescCodesForKanban,
    pkgDealDescCodesForPurchaseOrder,
  } = contract;
  return {
    code,
    packageDealDatabase,
    configuration: {
      hourlyRate,
      sparePartManagementType,
      sparePartMarginPercentage,
      roundPriceTo,
      pkgDealDescCodesForKanban,
      pkgDealDescCodesForPurchaseOrder,
      mandatoryFields,
      providerSpecificMargin,
    },
  };
}

function getDependingCustomerShortnames(
  customers: readonly Customer[],
  contractCode: string
): readonly string[] {
  return customers
    .filter((customer) => customer.contract === contractCode)
    .map((customer): string => customer.shortName);
}

function getCommonAttachmentsFolders(): readonly AttachmentFolder[] {
  return [
    EXPERTISE_ATTACHMENTS_FOLDER,
    TC_ATTACHMENT_FOLDER,
    QUALITY_CONTROL_ATTACHMENT_FOLDER,
    REGISTRATION_DOCUMENTS_ATTACHMENTS_FOLDER,
    ESTIMATE_ATTACHMENT_FOLDER,
    ACCEPTANCE_ATTACHMENT_FOLDER,
    INVOICE_ATTACHMENT_FOLDER,
    WORK_SHEET_ATTACHMENTS_FOLDER,
  ] as AttachmentFolder[];
}

function getAttachmentFolders(
  folders: readonly AttachmentFolder[],
  onlyShareable: boolean
): readonly AttachmentFolder[] {
  const filterVisibleFolders = (f: AttachmentFolder): boolean => !onlyShareable || f.isShareable;
  return [
    ...getCommonAttachmentsFolders().filter((f) => filterVisibleFolders(f)),
    ...folders.filter((f) => filterVisibleFolders(f)),
  ];
}

function hasMemoIdWithDot(val: MemoDesc): boolean {
  return val.id.includes('.');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAMemoDesc(val: any): val is MemoDesc {
  if (
    !isTruthy(val) ||
    (val.type !== undefined &&
      (typeof val.type !== 'string' ||
        !['boolean', 'numeric', 'text', 'select', 'textarea', 'date'].includes(val.type))) ||
    (val.showInEstimate !== undefined && typeof val.showInEstimate !== 'boolean') ||
    (val.hint !== undefined && typeof val.showInEstimate !== 'string') ||
    (val.additionalBehavior !== undefined &&
      (typeof val.additionalBehavior !== 'string' ||
        !['optional', 'naAllowed'].includes(val.additionalBehavior)))
  ) {
    return false;
  }

  if (val.type === 'select') {
    return (
      val.possibleValues !== undefined &&
      Array.isArray(val.possibleValues) &&
      val.possibleValues.filter((v: unknown) => typeof v !== 'string').length === 0
    );
  }
  return true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAnAttributeDesc(val: any): val is ContractAttributeDesc {
  return (
    val &&
    val.id &&
    typeof val.id === 'string' &&
    isTruthyAndNotEmpty(val.id) &&
    (val.showInEstimate === undefined || typeof val.showInEstimate === 'boolean') &&
    (val.keepValueWhenCloning === undefined || typeof val.keepValueWhenCloning === 'boolean') &&
    (val.mandatory === undefined || typeof val.mandatory === 'boolean') &&
    (val.displayInWorkshopPost === undefined || typeof val.displayInWorkshopPost === 'boolean') &&
    (val.quickAccessForStand === undefined ||
      (Array.isArray(val.quickAccessForStand) &&
        val.quickAccessForStand.filter((s: unknown) => typeof s !== 'string').length === 0))
  );
}

export const contractHelpers = {
  getCommonAttachmentsFolders,
  findContractByCode,
  getSharedUIContractFromContract,
  getUIContractFromContract,
  getDependingCustomerShortnames,
  getAttachmentFolders,
  getKanbanContractFromUIContract,
  isAMemoDesc,
  hasMemoIdWithDot,
  isAnAttributeDesc,
  getMandatoryFieldsForContract,
};
