import type { JSX } from 'react';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import type { MLMStoreDef } from '../store/typings/store.js';
import { MLM_FRONTEND_ROUTES } from './constants.js';
import './i18n.js';
import { MlmAppLayout } from './layout/MlmAppLayout.js';
import './scss/styles';

export interface MlmAppProps {
  readonly $gs: GlobalStoreStateSelector<MLMStoreDef>;
}

export function MlmApp({ $gs }: MlmAppProps): JSX.Element {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<MlmAppLayout $gs={$gs} />}>
        <Route
          loader={() => $gs.$home}
          path={MLM_FRONTEND_ROUTES.HOME.path}
          lazy={async () => ({
            Component: (await import('./pages/home/HomePage.js')).HomePage,
          })}
        />
        <Route
          loader={() => $gs.$customers}
          path={MLM_FRONTEND_ROUTES.CUSTOMERS.path}
          lazy={async () => ({
            Component: (await import('./pages/customers/CustomersPage.js')).CustomersPage,
          })}
        />
        <Route
          loader={() => $gs}
          path={MLM_FRONTEND_ROUTES.VEHICLES.path}
          lazy={async () => ({
            Component: (await import('./pages/vehicles/VehiclesPage.js')).VehiclesPage,
          })}
        />
        <Route
          path={MLM_FRONTEND_ROUTES.PROFILE.path}
          lazy={async () => ({
            Component: (await import('./pages/profile/ProfilePage.js')).ProfilePage,
          })}
        />
        <Route path="*" element={<Navigate replace to={MLM_FRONTEND_ROUTES.HOME.path} />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}
