import { EMPTY_CUSTOMERS_STATE } from '../app/pages/customers/store/store.js';
import { EMPTY_HOME_STATE } from '../app/pages/home/store/store.js';
import { EMPTY_VEHICLES_STATE } from '../app/pages/vehicles/store/store.js';
import type { StoreState } from './typings/store.js';

export const EMPTY_STORE_STATE: StoreState = {
  error: undefined,
  window: {
    height: 0,
    width: 0,
    bulmaMode: 'Mobile',
  },
  bulmaTheme: 'light',
  home: EMPTY_HOME_STATE,
  vehicles: EMPTY_VEHICLES_STATE,
  customers: EMPTY_CUSTOMERS_STATE,
};
