import type { PriceablePackageDeal } from '../../../model/typings/kanban.js';

export type BaseProgress = {
  readonly operationCount: number;
  readonly operationDone: number;
  readonly operationTotalWorkload: number;
  readonly operationDoneWorkload: number;
  readonly sparePartCount: number;
  readonly sparePartReceived: number;
};

export const EMPTY_BASE_PROGRESS: BaseProgress = {
  operationCount: 0,
  operationTotalWorkload: 0,
  operationDone: 0,
  operationDoneWorkload: 0,
  sparePartCount: 0,
  sparePartReceived: 0,
};

export type RevenueProgress = BaseProgress & {
  readonly operationDoneRevenue: number;
  readonly operationTotalRevenue: number;
  readonly sparePartReceivedRevenue: number;
  readonly sparePartTotalRevenue: number;
};

export const EMPTY_REVENUE_PROGRESS: RevenueProgress = {
  ...EMPTY_BASE_PROGRESS,
  operationDoneRevenue: 0,
  operationTotalRevenue: 0,
  sparePartReceivedRevenue: 0,
  sparePartTotalRevenue: 0,
};

export type PackageDealWithBaseProgress = PriceablePackageDeal & {
  readonly progressByStand: Record<string, BaseProgress>;
  readonly progressByFirm: Record<string, BaseProgress>;
};

export type PackageDealWithRevenueProgress = PriceablePackageDeal & {
  readonly progressByStand: Record<string, RevenueProgress>;
  readonly progressByFirm: Record<string, RevenueProgress>;
  readonly progress: RevenueProgress;
};
