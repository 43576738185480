import type { Deletable, Entity } from './kernel.js';

export const MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID = 'mktp-photos';
export const MARKETPLACE_TEXT_ATTACHMENTS_FOLDER_ID = 'mktp-texts';

export const MKTP_MANDATE_UNKNOWN = '';
export const MKTP_MANDATE_SALE = 'SALE';
export const MKTP_MANDATE_BUY = 'BUY';
export const MKTP_MANDATES = [MKTP_MANDATE_UNKNOWN, MKTP_MANDATE_BUY, MKTP_MANDATE_SALE];
export type MarketplaceMandate = (typeof MKTP_MANDATES)[number];

export const MKTP_OFFLINE_STATUS = 'offline';
export const MKTP_ONLINE_STATUS = 'online';
export const MKTP_SOLD_STATUS = 'sold';
export const MKTP_STATUSES = [MKTP_OFFLINE_STATUS, MKTP_ONLINE_STATUS, MKTP_SOLD_STATUS];
export type MarketplaceStatus = (typeof MKTP_STATUSES)[number];

export const MKTP_PROCESS_UNKNOWN = '';
export const MKTP_PROCESS_SALE = 'SALE';
export const MKTP_PROCESS_BUY = 'BUY';
export const MKTP_PROCESSES = [MKTP_PROCESS_UNKNOWN, MKTP_PROCESS_BUY, MKTP_PROCESS_SALE];
export type MarketplaceProcess = (typeof MKTP_PROCESSES)[number];

export const MKTP_GEARBOX_UNKNOWN = '';
export const MKTP_GEARBOX_AUTO = 'auto';
export const MKTP_GEARBOX_MANUAL = 'manual';
export const MKTP_GEARBOXES = [MKTP_GEARBOX_UNKNOWN, MKTP_GEARBOX_AUTO, MKTP_GEARBOX_MANUAL];
export type GearboxValue = (typeof MKTP_GEARBOXES)[number];

export const MKTP_ENERGY_UNKNOWN = '';
export const MKTP_ENERGY_DIESEL = 'diesel';
export const MKTP_ENERGY_GAS = 'gas';
export const MKTP_ENERGY_HYBRID = 'hybrid';
export const MKTP_ENERGY_ELECTRIC = 'electric';
export const MKTP_ENERGY_ETHANOL = 'ethanol';
export const MKTP_ENERGIES = [
  MKTP_ENERGY_UNKNOWN,
  MKTP_ENERGY_DIESEL,
  MKTP_ENERGY_GAS,
  MKTP_ENERGY_HYBRID,
  MKTP_ENERGY_ELECTRIC,
  MKTP_ENERGY_ETHANOL,
];
export type EnergyValue = (typeof MKTP_ENERGIES)[number];

export const MKTP_WARRANTY_EXTENSION_NOT_SPECIFIED = 'unknown';
export const MKTP_WARRANTY_EXTENSION_T3_12_MONTHS = '12MonthsT3';
export const MKTP_WARRANTY_EXTENSION_T3_24_MONTHS = '24MonthsT3';
export const MKTP_WARRANTY_EXTENSION_T3_36_MONTHS = '36MonthsT3';
export const MKTP_WARRANTY_EXTENSION_T3_48_MONTHS = '48MonthsT3';
export const MKTP_WARRANTY_EXTENSION_T4_12_MONTHS = '12MonthsT4';
export const MKTP_WARRANTY_EXTENSION_T4_24_MONTHS = '24MonthsT4';
export const MKTP_WARRANTY_EXTENSION_T4_36_MONTHS = '36MonthsT4';
export const MKTP_WARRANTY_EXTENSION_T4_48_MONTHS = '48MonthsT4';
export const MKTP_WARRANTY_EXTENSION_T5_12_MONTHS = '12MonthsT5';
export const MKTP_WARRANTY_EXTENSION_T5_24_MONTHS = '24MonthsT5';
export const MKTP_WARRANTY_EXTENSION_T5_36_MONTHS = '36MonthsT5';
export const MKTP_WARRANTY_EXTENSION_T5_48_MONTHS = '48MonthsT5';
export const MKTP_WARRANTY_EXTENSIONS = [
  MKTP_WARRANTY_EXTENSION_NOT_SPECIFIED,
  MKTP_WARRANTY_EXTENSION_T3_12_MONTHS,
  MKTP_WARRANTY_EXTENSION_T3_24_MONTHS,
  MKTP_WARRANTY_EXTENSION_T3_36_MONTHS,
  MKTP_WARRANTY_EXTENSION_T3_48_MONTHS,
  MKTP_WARRANTY_EXTENSION_T4_12_MONTHS,
  MKTP_WARRANTY_EXTENSION_T4_24_MONTHS,
  MKTP_WARRANTY_EXTENSION_T4_36_MONTHS,
  MKTP_WARRANTY_EXTENSION_T4_48_MONTHS,
  MKTP_WARRANTY_EXTENSION_T5_12_MONTHS,
  MKTP_WARRANTY_EXTENSION_T5_24_MONTHS,
  MKTP_WARRANTY_EXTENSION_T5_36_MONTHS,
  MKTP_WARRANTY_EXTENSION_T5_48_MONTHS,
];
export type WarrantyExtensionValue = (typeof MKTP_WARRANTY_EXTENSIONS)[number];

export const MKTP_ECO_STICKER_UNKNOWN = '';
export const MKTP_ECO_STICKER_ELEC_OR_HYDRO = 'elec-or-hydro';
export const MKTP_ECO_STICKER_LVL_1 = 'level-1';
export const MKTP_ECO_STICKER_LVL_2 = 'level-2';
export const MKTP_ECO_STICKER_LVL_3 = 'level-3';
export const MKTP_ECO_STICKER_LVL_4 = 'level-4';
export const MKTP_ECO_STICKER_LVL_5 = 'level-5';
export const MKTP_ECO_STICKERS = [
  MKTP_ECO_STICKER_UNKNOWN,
  MKTP_ECO_STICKER_ELEC_OR_HYDRO,
  MKTP_ECO_STICKER_LVL_1,
  MKTP_ECO_STICKER_LVL_2,
  MKTP_ECO_STICKER_LVL_3,
  MKTP_ECO_STICKER_LVL_4,
  MKTP_ECO_STICKER_LVL_5,
];
export type EcoStickerValue = (typeof MKTP_ECO_STICKERS)[number];

export type MainSpecs = {
  readonly energy?: EnergyValue;
  readonly gearbox?: GearboxValue;
  readonly horsepower?: number;
  readonly taxHorsepower?: number;
  readonly doors?: number;
  readonly seats?: number;
  readonly ecoSticker?: string;
};

export const MKTP_EQUIPMENT_CATEGORY_SECURITY = 'security';
export const MKTP_EQUIPMENT_CATEGORY_COMFORT = 'comfort';
export const MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA = 'multimedia';
export const MKTP_EQUIPMENT_CATEGORY_OTHERS = 'others';
export const MKTP_EQUIPMENT_CATEGORIES = [
  MKTP_EQUIPMENT_CATEGORY_SECURITY,
  MKTP_EQUIPMENT_CATEGORY_COMFORT,
  MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA,
  MKTP_EQUIPMENT_CATEGORY_OTHERS,
] as const;
export type EquipmentCategory = (typeof MKTP_EQUIPMENT_CATEGORIES)[number];

export const MKTP_PAYMENT_CLIENT_UNKNOWN = '';
export const MKTP_PAYMENT_CLIENT_SELLER = 'seller';
export const MKTP_PAYMENT_CLIENT_BUYER = 'buyer';
export const MKTP_PAYMENT_CLIENTS = [
  MKTP_PAYMENT_CLIENT_UNKNOWN,
  MKTP_PAYMENT_CLIENT_BUYER,
  MKTP_PAYMENT_CLIENT_SELLER,
];
export type MarketplacePaymentClient = (typeof MKTP_PAYMENT_CLIENTS)[number];

export const MKTP_PAYMENT_TYPE_UNKNOWN = '';
export const MKTP_PAYMENT_TYPE_BANK_TRANSFER = 'bankTransfer';
export const MKTP_PAYMENT_TYPE_CASH_SENTINEL = 'cashSentinel';
export const MKTP_PAYMENT_TYPE_CHEQUE = 'cheque';
export const MKTP_PAYMENT_TYPE_PAYPAL = 'paypal';
export const MKTP_PAYMENT_TYPE_STRIPE = 'stripe';
export const MKTP_PAYMENT_TYPES = [
  MKTP_PAYMENT_TYPE_UNKNOWN,
  MKTP_PAYMENT_TYPE_BANK_TRANSFER,
  MKTP_PAYMENT_TYPE_CASH_SENTINEL,
  MKTP_PAYMENT_TYPE_CHEQUE,
  MKTP_PAYMENT_TYPE_PAYPAL,
  MKTP_PAYMENT_TYPE_STRIPE,
] as const;
export type MarketplacePaymentType = (typeof MKTP_PAYMENT_TYPES)[number];

export const MKTP_PKG_CATEGORY_NA = 'NA';
export const MKTP_PKG_CATEGORY_REGISTRATION_FEES = 'REGISTRATION_FEES';
export const MKTP_PKG_CATEGORY_BUY_FIXED_FEES = 'BUY_FIXED_FEES';
export const MKTP_PKG_CATEGORY_SALE_FIXED_FEES = 'SALE_FIXED_FEES';

export const MARKETPLACE_CATEGORIES = [
  MKTP_PKG_CATEGORY_NA,
  MKTP_PKG_CATEGORY_REGISTRATION_FEES,
  MKTP_PKG_CATEGORY_BUY_FIXED_FEES,
  MKTP_PKG_CATEGORY_SALE_FIXED_FEES,
] as const;
export type MarketplaceCategory = (typeof MARKETPLACE_CATEGORIES)[number];

export type MarketplacePayment = {
  readonly client: MarketplacePaymentClient;
  readonly paymentRequestDate: number;
  readonly paymentCompletionDate: number;
  readonly paymentType: MarketplacePaymentType;
  readonly priceWithVAT: string;
  readonly description: string;
};

export type MarketplacePaymentEntity = Entity & Deletable & MarketplacePayment;

export type MarketplaceInfos = {
  readonly mandate: MarketplaceMandate;
  readonly inputPrice: number; // Either public price (SALE mandate) or sale price (BUY mandate)
  readonly negotiation: number;
  readonly canceledPackageDealIds: readonly string[];
  readonly depositThirdPartyService: string;
  readonly deposit: number;
  readonly textAttachment: string;
  readonly mainSpecs: MainSpecs;
  readonly equipments: Record<EquipmentCategory, readonly string[]>;
  readonly warrantyExtension: WarrantyExtensionValue;
  readonly loanAmount: number;
  readonly stimerId: string;
  readonly paymentHistory: readonly MarketplacePaymentEntity[];
};
