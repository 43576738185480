import type { JSX } from 'react';
import React, { useEffect } from 'react';
import type { ActionContext, AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '../elements/icons/FaIcon.js';
import type { FormFieldEntry } from './typings/FormFieldEntry.js';
import { useSortableFormFieldEntries } from './useSortableFormFieldEntries.js';

export interface SelectProps<
  SD extends AnyStoreDef,
  T extends string | number | boolean | undefined,
> {
  readonly isFullWidth?: boolean;
  readonly isEmptyValueAllowed?: boolean;
  readonly entries: readonly (FormFieldEntry<NonNullable<T>> | NonNullable<T>)[];
  readonly $: StoreStateSelector<SD, T>;
  readonly disabled?: boolean;
  readonly sortEntries?: boolean;
  readonly isSmall?: boolean;
  readonly iconId?: string;
}

function updateDispatchValueAction<
  SD extends AnyStoreDef,
  T extends string | number | boolean | undefined,
>({ actionDispatch, getState }: ActionContext<SD, T>, value: string) {
  const selectedEntryId = getState();
  switch (typeof selectedEntryId) {
    case 'boolean':
      actionDispatch.setValue((value.trim().toLowerCase() === 'true') as T);
      break;
    case 'number':
      actionDispatch.setValue(Number.parseFloat(value) as T);
      break;
    default:
      actionDispatch.setValue(value as T);
  }
}

export function Select<SD extends AnyStoreDef, T extends string | number | boolean | undefined>({
  $,
  isFullWidth = false,
  isEmptyValueAllowed = false,
  entries,
  disabled = false,
  sortEntries = false,
  isSmall = false,
  iconId,
}: SelectProps<SD, T>): JSX.Element {
  const selectedEntryId = useGetState($);

  const updateDispatchValueActionCallback = useActionCallback(updateDispatchValueAction, [], $);

  // FIXME : strange to initialize an input value through a useEffect : this should be removed
  // The parent component should be responsible for initializing correctly the state before
  // the current input is mounted
  useEffect(() => {
    async function asyncFunction(): Promise<void> {
      if (
        !isEmptyValueAllowed &&
        (!isTruthy(selectedEntryId) ||
          (typeof selectedEntryId === 'string' && !isTruthyAndNotEmpty(selectedEntryId)))
      ) {
        let entry = entries[0];
        if (typeof entry === 'object') {
          entry = entry.id;
        }
        await updateDispatchValueActionCallback(String(entry));
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncFunction();
  }, [entries, isEmptyValueAllowed, selectedEntryId, updateDispatchValueActionCallback]);

  const onChangeActionCallback = useActionCallback(
    async function onChangeAction({ actionDispatch }, e: React.ChangeEvent<HTMLSelectElement>) {
      await actionDispatch.exec(updateDispatchValueAction, e.target.value);
    },
    [],
    $
  );

  const sortableEntries = useSortableFormFieldEntries({ entries, sortEntries });

  return (
    <div className={`control${iconId ? ' has-icons-left' : ''}`}>
      <div className={`select${isFullWidth ? ' is-fullwidth' : ''}${isSmall ? ' is-small' : ''}`}>
        <select
          id="site"
          value={String(selectedEntryId)}
          onChange={onChangeActionCallback}
          disabled={disabled}
        >
          {isEmptyValueAllowed && <option value="" aria-label="empty" />}
          {sortableEntries.map(({ id: optionId, label: optionLabel }): JSX.Element => {
            return (
              <option key={String(optionId)} value={String(optionId)}>
                {optionLabel}
              </option>
            );
          })}
        </select>
      </div>
      {iconId && <FaIcon id={iconId} additionalClass={`is-left${isSmall ? ' is-small' : ''}`} />}
    </div>
  );
}
