export const DEFAULT_VAT_RATE = 20;

function getPriceInCents(price: number): number {
  const epsilon = 1e-6;
  const correction = price > 0 ? epsilon : -epsilon;
  const priceWithCorrection = price + correction;
  return Math.round(priceWithCorrection * 100);
}

function isSameAmount(price1: number, price2: number): boolean {
  return getPriceInCents(price1) === getPriceInCents(price2);
}

function getPriceWithoutVAT(priceWithVAT: number, VATRate: number = DEFAULT_VAT_RATE): number {
  return priceWithVAT / (1 + VATRate);
}

function getVAT(priceWithoutVAT: number, VATRate: number = DEFAULT_VAT_RATE): number {
  return priceWithoutVAT * (VATRate / 100);
}

function getPriceWithVAT(priceWithoutVAT: number, VATRate: number = DEFAULT_VAT_RATE): number {
  const VAT = getVAT(priceWithoutVAT, VATRate);
  return priceWithoutVAT + VAT;
}

export const priceHelpers = {
  isSameAmount,
  getVAT,
  getPriceWithVAT,
  getPriceWithoutVAT,
};
