import { useMemo } from 'react';
import type { BulmaMode } from '../../react/bulma/typings/bulma.js';
import type { StoreStateSelector } from '../../zustand/typings/zustand-dispatch.js';
import { useGetState } from '../../zustand/zustand-hooks.js';
import type { WindowState, WindowStateStoreDef } from './useWindowSizeHandler.js';

export function useScreenMatchesBulmaScreenSize<SD extends WindowStateStoreDef>(
  $window: StoreStateSelector<SD, WindowState>,
  ...modes: readonly BulmaMode[]
): boolean {
  const bulmaMode = useGetState($window.$bulmaMode);
  return useMemo(() => modes.includes(bulmaMode), [bulmaMode, modes]);
}

export function useScreenWidthLessThan<SD extends WindowStateStoreDef>(
  $window: StoreStateSelector<SD, WindowState>,
  width: number
): boolean {
  const bulmaWidth = useGetState($window.$width);
  return useMemo(() => bulmaWidth < width, [bulmaWidth, width]);
}

export function useScreenIsBulmaMobile<SD extends WindowStateStoreDef>(
  $window: StoreStateSelector<SD, WindowState>
): boolean {
  return useScreenMatchesBulmaScreenSize($window, 'Mobile');
}
