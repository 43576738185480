import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from './FormField.js';
import type { ListSelectProps } from './ListSelect.js';
import { FormField } from './FormField.js';
import { ListSelect } from './ListSelect.js';

export interface ListSelectFormFieldProps<
  SD extends AnyStoreDef,
  T extends string | readonly string[],
> extends ListSelectProps<SD, T>,
    Omit<FormFieldProps, 'children' | 'warning' | 'noExclamationTriangleIfWarning'> {}

export function ListSelectFormField<SD extends AnyStoreDef, T extends string | readonly string[]>({
  label,
  horizontal,
  $,
  ...props
}: ListSelectFormFieldProps<SD, T>): JSX.Element {
  const warning = useFormFieldWarning($);
  return (
    <FormField label={label} warning={warning} horizontal={horizontal}>
      <ListSelect {...props} $={$} />
    </FormField>
  );
}
