import type { JSX } from 'react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { useStateIsDefined } from '@stimcar/libs-uikernel';
import type { MLMStoreDef } from '../../store/typings/store.js';
import { useOnNavigation } from '../hooks/useOnNavigation.js';
import { UnexpectedError } from '../UnexpectedError.js';
import { NavBar } from './NavBar.js';

export interface MlmAppLayoutProps {
  readonly $gs: GlobalStoreStateSelector<MLMStoreDef>;
}

export function MlmAppLayout({ $gs }: MlmAppLayoutProps): JSX.Element {
  useOnNavigation($gs);
  const hasError = useStateIsDefined($gs.$error);
  return (
    <>
      <div className="p-3">{!hasError ? <Outlet /> : <UnexpectedError $gs={$gs} />}</div>
      <NavBar />
    </>
  );
}
