import type { EventSourceFactory } from '@stimcar/libs-base';
import type { Fetch, FormDataFactory, HttpLogFormatter, JsonParser } from '@stimcar/libs-kernel';
import { HTTPClientWithSSEImpl, HttpErrorCodes } from '@stimcar/libs-base';
import { getHttpStatusCode } from '@stimcar/libs-kernel';
import { MlmAuthenticationRoutes, MlmSSEMessages } from '@stimcar/mlm-libs-common';
import type { MlmHttpClient } from './typings/MlmHttpClient.js';

const FOUR_MINUTES_IN_MS = 4 * 60 * 1000;

export class MlmHTTPClientImpl extends HTTPClientWithSSEImpl implements MlmHttpClient {
  constructor(
    fetch: Fetch,
    formDataFactory: FormDataFactory,
    evFactory: EventSourceFactory,
    baseUrl?: string,
    logFormatter?: HttpLogFormatter,
    jsonParser?: JsonParser
  ) {
    super(fetch, formDataFactory, evFactory, baseUrl, logFormatter, jsonParser);

    const refreshToken = async () => {
      await this.httpGet(MlmAuthenticationRoutes.REFRESH_TOKEN);
    };

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setInterval(refreshToken, FOUR_MINUTES_IN_MS);
  }

  public startSSE() {
    this.restartSSE(MlmSSEMessages.SSE);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected async handleFetchError(e: Error, _path: string, _init: RequestInit): Promise<void> {
    if (getHttpStatusCode(e) === HttpErrorCodes.UNAUTHORIZED) {
      const { redirectUrl } = await this.httpGetAsJson<{ readonly redirectUrl: string }>(
        MlmAuthenticationRoutes.LOGIN
      );
      window.location.href = redirectUrl;
    }
  }
}
